<template>
  <section
    class="entity-data-view-section"
    v-loading.fullscreen.lock="loading"
    :element-loading-text="LoadingText"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div v-if="checkPerimission('VIEW')" style="text-align: center">
      <h2 style="color: red">Don't have access</h2>
      <el-link @click="backToEntityDataList" :underline="false">Back</el-link>
    </div>
    <div v-else>
      <el-button
        size="mini"
        icon="el-icon-back"
        v-if="getIsMobile"
        class="default-button btn button-left"
        @click="backToEntityDataList()"
        >Back</el-button
      >
      <el-row>
        <!-- <div class="header-action-block"> -->
        <el-col :xl="2" :lg="2" :mg="2" :sm="2" :xs="2">
          <el-button
            v-if="!getIsMobile"
            size="mini"
            icon="el-icon-back"
            class="default-button btn button-left"
            :style="getIsMobile ? '' : 'margin-top: 21px'"
            @click="backToEntityDataList()"
            >Back</el-button
          >
        </el-col>
        <!-- <el-col :xl="6" :lg="6" :mg="6" :sm="4" :xs="4">
        <h5 v-if="getEntityDataById && getEntityDataById.name">
          {{ getIsMobile ? getPrimaryTitle+' '+'Profile' : getEntityDataById.name }}
        </h5>
        </el-col>-->
        <!-- </div> -->
        <!-- <el-row> -->
        <!-- </el-row> -->
        <el-row v-if="getIsMobile">
          <el-col :span="24">
            <p
              v-html="getEntityDataById.description"
              v-if="getEntityDataById && getEntityDataById.description"
            ></p>
          </el-col>
        </el-row>
        <!-- <el-row :gutter="12"> -->
        <el-col
          v-if="!getIsMobile"
          :xl="{ span: 8 }"
          :lg="{ span: 8 }"
          :md="{ span: 24 }"
          :sm="{ span: 6 }"
          :xs="{ span: 6 }"
        >
          <el-card shadow="never">
            <img
              class="imgsty"
              src="@/assets/img/icons/entity-stat-icons/avatar-person.svg"
              alt="icon"
            />
            <p
              class="namesty"
              v-if="
                getPrimaryFields &&
                getPrimaryFields[0] &&
                getPrimaryFields[0].inputType &&
                getPrimaryFields[0].inputType === 'DATE' &&
                getPrimaryTitle
              "
            >
              {{ getPrimaryTitle | globalDateFormat }}
            </p>
            <p class="namesty" v-else>
              {{
                getPrimaryTitle +
                " " +
                (getEntityDataById && getEntityDataById.name
                  ? getEntityDataById.name
                  : "") +
                " " +
                "Profile"
              }}
            </p>
          </el-card>
        </el-col>
        <el-col
          :xl="{ span: 4 }"
          :lg="{ span: 4 }"
          :md="{ span: 12 }"
          :sm="{ span: 8 }"
          :xs="{ span: 8 }"
          :class="getIsMobile ? 'resStyle' : 'statstyle'"
          :style="
            this.$route.query.activeStep == 1
              ? 'background-color: #ff679d'
              : 'background-color: #ffdde9'
          "
        >
          <div
            :class="
              this.$route.query.activeStep == 1
                ? 'textActiveStyle'
                : 'textstatstyle'
            "
            v-on:click="handleStats(1)"
          >
            <p :style="getIsMobile ? 'font-size:10px' : ''">Entities Data</p>
            <img
              :class="getIsMobile ? 'iconResStyle' : 'iconStyle'"
              src="@/assets/img/icons/entity-stat-icons/IconEntitiesInactive.svg"
              alt="icon"
            />
          </div>

          <span
            v-if="
              this.getEntityDataById &&
              this.getEntityDataById.templates &&
              this.getEntityDataById.templates.length
            "
            v-on:click="handleStats(1)"
            style="cursor: pointer"
          >
            <p
              :class="
                this.$route.query.activeStep == 1
                  ? 'number_sty_active fs-6 fw-light '
                  : 'number_sty fs-6 fw-light '
              "
            >
              {{ this.getEntityDataById.templates.length }}
            </p>
          </span>
        </el-col>
        <el-col
          :xl="{ span: 4 }"
          :lg="{ span: 4 }"
          :md="{ span: 12 }"
          :sm="{ span: 8 }"
          :xs="{ span: 8 }"
          :class="getIsMobile ? 'resStyle' : 'statstyle'"
          :style="
            this.$route.query.activeStep == 2
              ? 'background-color: #40c0f0'
              : 'background-color: #e8f9ff'
          "
        >
          <div
            :class="
              this.$route.query.activeStep == 2
                ? 'textActiveStyle'
                : 'textstatstyle'
            "
            v-on:click="handleStats(2)"
          >
            <p :style="getIsMobile ? 'font-size:10px' : ''">Documents</p>
            <img
              :class="getIsMobile ? 'iconResStyle' : 'iconStyle'"
              src="@/assets/img/icons/entity-stat-icons/DocumentsInactive.svg"
              alt="icon"
            />
          </div>
          <span
            v-if="this.templates && this.templates.length"
            style="cursor: pointer"
            v-on:click="handleStats(2)"
          >
            <p
              :class="
                this.$route.query.activeStep == 2
                  ? 'number_sty_active fs-6 fw-light '
                  : 'number_sty fs-6 fw-light '
              "
            >
              {{ this.templatesCount }}
            </p>
          </span>
          <span v-else>
            <p
              :class="
                this.$route.query.activeStep == 2
                  ? 'number_sty_active fs-6 fw-light '
                  : 'number_sty fs-6 fw-light '
              "
            >
              {{ 0 }}
            </p>
          </span>
        </el-col>
        <el-col
          :xl="{ span: 4 }"
          :lg="{ span: 4 }"
          :md="{ span: 12 }"
          :sm="{ span: 8 }"
          :xs="{ span: 8 }"
          :class="getIsMobile ? 'resStyle' : 'statstyle'"
          :style="
            this.$route.query.activeStep == 3
              ? 'background-color: #f2dd27'
              : 'background-color: #fffbd9'
          "
        >
          <div
            :class="
              this.$route.query.activeStep == 3
                ? 'textActiveStyle'
                : 'textstatstyle'
            "
            v-on:click="handleStats(3)"
          >
            <p :style="getIsMobile ? 'font-size:10px' : ''">Document Data</p>
            <img
              :class="getIsMobile ? 'iconResStyle' : 'iconStyle'"
              src="@/assets/img/icons/entity-stat-icons/DocumentDataInactive.svg"
              alt="icon"
            />
          </div>
          <span
            v-if="this.templates && this.templates.length"
            style="cursor: pointer"
            v-on:click="handleStats(3)"
          >
            <p
              :class="
                this.$route.query.activeStep == 3
                  ? 'number_sty_active fs-6 fw-light '
                  : 'number_sty fs-6 fw-light '
              "
            >
              {{ this.templatesCount }}
            </p>
          </span>
          <span v-else v-on:click="handleStats(3)">
            <p
              :class="
                this.$route.query.activeStep == 3
                  ? 'number_sty_active fs-6 fw-light '
                  : 'number_sty fs-6 fw-light '
              "
            >
              {{ 0 }}
            </p>
          </span>
        </el-col>
        <!-- <el-col
          :xl="{ span: 4 }"
          :lg="{ span: 4 }"
          :md="{ span: 12 }"
          :sm="{ span: 12 }"
          :xs="{ span: 12 }"
          :class="getIsMobile ? 'resStyle' : 'statstyle'"
          :style="
            this.$route.query.activeStep == 4
              ? 'background-color: #08ba73'
              : 'background-color: #edfcf6'
          "
        >
          <div
            :class="
              this.$route.query.activeStep == 4
                ? 'textActiveStyle'
                : 'textstatstyle'
            "
            v-on:click="handleStats(4)"
          >
            Data Workflows
            <img
              :class="getIsMobile ? 'iconResStyle' : 'iconStyle'"
              src="@/assets/img/icons/entity-stat-icons/DataWorkflowsInactive.svg"
              alt="icon"
            />
          </div>
          <span>
            <p
              :class="
                this.$route.query.activeStep == 4
                  ? 'number_sty_active fs-6 fw-light '
                  : 'number_sty fs-6 fw-light '
              "
            >
              {{ 0 }}
            </p>
          </span>
        </el-col>-->
      </el-row>
      <!-- *** entities data block start *** -->
      <template v-if="getAllSteps && getAllSteps.length && activeStep === 1">
        <div style="display: flex !important" v-if="getIsMobile">
          <el-button
            :icon="toggleMenu ? 'el-icon-s-fold' : 'el-icon-s-unfold'"
            class="mt-1"
            @click="displayToggle"
          ></el-button>
          <div class="list-group-menu" v-if="toggleMenu && getIsMobile">
            <el-menu class="el-menu-vertical-demo sidemenu">
              <el-menu-item
                v-if="
                  getEntityDataById &&
                  getEntityDataById.entity_type === 'BUSINESS' &&
                  checkIsFilteredData
                "
                index="2"
                key="contacts"
              >
                <router-link :to="getStepUrl({}, false, 'contacts')">
                  <img
                    class
                    src="@/assets/img/template-icons/block-portfolio.svg"
                    alt="icons"
                    width="20"
                  />
                  <span>Contacts</span>
                </router-link>
              </el-menu-item>
              <el-menu-item
                index="1"
                v-for="(step, index) of getAllSteps"
                :key="'templates-' + index"
              >
                <router-link
                  :class="getClass(step)"
                  :to="getStepUrl(step, true, '')"
                >
                  <el-tooltip
                    v-if="getIsMobile"
                    trigger="hover"
                    class="item"
                    effect="dark"
                    :content="step.templateInfo.name"
                    placement="top-start"
                  >
                    <img
                      class
                      v-if="step.templateInfo.icon"
                      :src="
                        require('@/assets/img/template-icons/' +
                          step.templateInfo.icon)
                      "
                      alt="icon"
                      width="15"
                    />

                    <img
                      v-else
                      class
                      src="@/assets/img/template-icons/block-portfolio.svg"
                      alt="icons"
                      width="15"
                    />
                  </el-tooltip>
                  <span style="color: #000000; font-size: 10px">
                    {{ step.templateInfo.name | truncate(10, "...") }}
                  </span>
                </router-link>
              </el-menu-item>
              <template v-if="checkIsFilteredData">
                <el-menu-item
                  index="2"
                  v-for="(reationship, index) of relationshipsData"
                  :key="'reationships' + index"
                >
                  <router-link
                    :class="getClass(reationship)"
                    :to="getStepEntityRelationshipUrl(reationship)"
                  >
                    <img
                      class
                      src="@/assets/img/template-icons/block-portfolio.svg"
                      alt="icons"
                      width="20"
                    />
                    <span>{{
                      reationship.relationship_title | truncate(25, "...")
                    }}</span>
                  </router-link>
                </el-menu-item>
              </template>
              <hr />
            </el-menu>
          </div>
        </div>
        <div>
          <el-row>
            <!-- <el-col v-if="getIsMobile" :md="4" :sm="6" :xs="6">
              <div class="list-group-res">
                <el-menu class="el-menu-vertical-demo sidemenu">
                  <el-menu-item
                    v-if="
                      getEntityDataById.isAccount &&
                      getEntityDataById.entity_type != 'INDIVIDUAL'
                    "
                    index="2"
                    key="contacts"
                  >
                    <router-link :to="getStepUrl({}, false, 'contacts')">
                      <img
                        class=""
                        src="@/assets/img/template-icons/block-portfolio.svg"
                        alt="icons"
                        width="20"
                      />
                      <span> Contacts </span>
                    </router-link>
                  </el-menu-item>
                  <el-menu-item
                    index="1"
                    v-for="(step, index) of getAllSteps"
                    :key="'templates-' + index"
                  >
                    <router-link
                      :class="getClass(step)"
                      :to="getStepUrl(step, true, '')"
                    >
                      <el-tooltip
                        v-if="getIsMobile"
                        trigger="hover"
                        class="item"
                        effect="dark"
                        :content="step.templateInfo.name"
                        placement="top-start"
                      >
                        <img
                          class=""
                          v-if="step.templateInfo.icon"
                          :src="
                            require('@/assets/img/template-icons/' +
                              step.templateInfo.icon)
                          "
                          alt="icon"
                          width="15"
                        />

                        <img
                          v-else
                          class=""
                          src="@/assets/img/template-icons/block-portfolio.svg"
                          alt="icons"
                          width="15"
                        />
                      </el-tooltip>
                      <span style="color: #000000; font-size: 10px">{{
                        step.templateInfo.name | truncate(10, "...")
                      }}</span>
                    </router-link>
                  </el-menu-item>
                  <el-menu-item
                    index="2"
                    v-for="(reationship, index) of relationshipsData"
                    :key="'reationships' + index"
                  >
                    <router-link
                      :class="getClass(reationship)"
                      :to="getStepEntityRelationshipUrl(reationship)"
                    >
                      <img
                        class=""
                        src="@/assets/img/template-icons/block-portfolio.svg"
                        alt="icons"
                        width="20"
                      />
                      <span>
                        {{
                          reationship.relationship_title | truncate(25, "...")
                        }}
                      </span>
                    </router-link>
                  </el-menu-item>
                  <hr />
                </el-menu>
              </div>
            </el-col>-->
            <el-col v-if="!getIsMobile" :md="6" :sm="6" :xs="24">
              <!-- <el-scrollbar class="sidemenu"> -->
              <div class="list-group">
                <el-menu class="el-menu-vertical-demo sidemenu">
                  <el-menu-item
                    v-if="
                      getEntityDataById &&
                      getEntityDataById.entity_type === 'BUSINESS' &&
                      checkIsFilteredData
                    "
                    index="2"
                    key="contacts"
                  >
                    <router-link :to="getStepUrl({}, false, 'contacts')">
                      <img
                        style="margin-left: -5px"
                        class="mr-1"
                        src="@/assets/img/template-icons/block-portfolio.svg"
                        alt="icons"
                        width="20"
                      />
                      <span>Contacts</span>
                    </router-link>
                  </el-menu-item>
                  <el-menu-item
                    index="1"
                    v-for="(step, index) of getAllSteps"
                    :key="'templates-' + index"
                  >
                    <router-link
                      :class="getClass(step)"
                      :to="getStepUrl(step, true, '')"
                    >
                      <img
                        class="ml-2 mr-1"
                        v-if="step.templateInfo.icon"
                        :src="
                          require('@/assets/img/template-icons/' +
                            step.templateInfo.icon)
                        "
                        alt="icon"
                        width="20"
                      />

                      <img
                        v-else
                        class="ml-2 mr-1"
                        src="@/assets/img/template-icons/block-portfolio.svg"
                        alt="icons"
                        width="20"
                      />
                      <span>{{
                        step.templateInfo.name | truncate(25, "...")
                      }}</span>
                    </router-link>
                  </el-menu-item>
                  <template v-if="checkIsFilteredData">
                    <el-menu-item
                      index="2"
                      v-for="(reationship, index) of relationshipsData"
                      :key="'reationships' + index"
                    >
                      <router-link
                        :class="getClass(reationship)"
                        :to="getStepEntityRelationshipUrl(reationship)"
                      >
                        <img
                          class="ml-2 mr-1"
                          src="@/assets/img/template-icons/block-portfolio.svg"
                          alt="icons"
                          width="20"
                        />
                        <span>
                          {{
                            reationship.relationship_title | truncate(25, "...")
                          }}
                        </span>
                      </router-link>
                    </el-menu-item>
                  </template>
                  <hr />
                  <template v-if="checkIsFilteredData">
                    <el-menu-item
                      index="2"
                      v-for="(reationship, index) of nestedRelationshipsData"
                      :key="'nestedreationships' + index"
                    >
                      <router-link
                        :class="getClass(reationship)"
                        :to="getStepEntityRelationshipUrl(reationship)"
                      >
                        <img
                          class="ml-2 mr-1"
                          src="@/assets/img/template-icons/block-portfolio.svg"
                          alt="icons"
                          width="20"
                        />
                        <span>
                          {{
                            reationship.relationship_title | truncate(25, "...")
                          }}
                        </span>
                      </router-link>
                    </el-menu-item>
                  </template>
                </el-menu>
              </div>
              <!-- </el-scrollbar> -->
            </el-col>
            <el-col
              v-if="!getIsMobile"
              class="secondcol"
              :md="18"
              :sm="18"
              :xs="24"
            >
              <router-view
                :layout.sync="layout"
                :key="$route.fullPath"
              ></router-view>
            </el-col>
            <el-col
              v-if="getIsMobile"
              class="secondcol-res"
              :md="24"
              :sm="24"
              :xs="24"
            >
              <router-view
                :layout.sync="layout"
                :key="$route.fullPath"
              ></router-view>
            </el-col>
          </el-row>
        </div>
      </template>
      <!-- ** entities block end ** -->
      <!-- *** entities document templates block start *** -->
      <template v-if="activeStep === 2">
        <EntityDocuments
          :entityId="entityId"
          :entityDataId="entityDataId"
          :isDocument="isDocument"
        />
      </template>
      <!-- <template v-if="activeStep === 2 && getIsMobile">
        <UserTemplatesTable
          :entityId="entityId"
          :entityDataId="entityDataId"
          :isDocument="isDocument"
        />
      </template>-->
      <!-- *** entities document templates block end *** -->

      <!-- *** entities document data block start *** -->
      <template v-if="activeStep === 3">
        <EntityDocumentsData
          :entityId="entityId"
          :entityDataId="entityDataId"
        />
      </template>
      <!-- *** entities document data block end *** -->
      <!-- *** entities data workflows block start *** -->
      <template v-if="activeStep === 4">
        <EntityDataWorkflows
          :entityId="entityId"
          :entityDataId="entityDataId"
        />
      </template>
      <!-- *** entities data workflows block end *** -->
    </div>
  </section>
</template>

<script>
// import { bus } from "../../main";
import { mapGetters, mapActions } from "vuex";
import CompanyDocumentsHelper from "@/mixins/CompanyDocumentsHelper";
import EntityDocuments from "./userProfileEntityDocumentTemplates";
import EntityDocumentsData from "./userProfileEntityDocumentsData";
import EntityDataWorkflows from "./userProfileEntityDataWorkflows";
import { makeSlug } from "../../helpers/appHelper";
import EntityHelper from "@/mixins/EntitiesHelper";
import NavigationHelper from "@/mixins/navigationHelper";
//import UserTemplatesTable from "./userTemplatesTableInEntityProfiles";
import { fetchEntityRelationships } from "@/repo/entityRelationshipsRepo";
export default {
  name: "Entity-EntityPreview",
  components: {
    EntityDocuments,
    EntityDocumentsData,
    EntityDataWorkflows,
    //  UserTemplatesTable,
  },
  mixins: [CompanyDocumentsHelper, EntityHelper, NavigationHelper],
  data() {
    return {
      entityId: null,
      activeStep: 1,
      loading: false,
      LoadingText: "",
      step: 1,
      isDocument: false,
      templatesCount: [],
      templates: [],
      array: [],
      employeeName: "",
      number: "",
      slug: null,
      docsNum: null,
      templatesNum: null,
      layout: "div",
      entityDocumentTemplates: [],
      employeeData: {},
      entityDataId: null,
      relationshipsData: [],
      nestedRelationshipsData: [],
      toggleMenu: false,
      fetchedRelationships: null
    };
  },
  async mounted() {
    this.getNecessaryInfo();
    if (this.$route.query.dataId) {
      await this.fetchEntityData(this.$route.query.dataId);
    }
    this.getEntityPrimaryTitle();
  },

  created() {
    // bus.$on("next-step", (data) => {
    //   this.gotoNextStep();
    // });
  },
  computed: {
    ...mapGetters("entities", [
      "getEntityDataById",
      "getEntityDocumentsDataStatus",
      "getEntityDocumentsData",
      "getEntityDocumentsNotIncludingTemplatesData",
      "getCompanyEntityDetails",
    ]),
    ...mapGetters("navigationOpen", ["getIsMobile", "getIsRSOpen"]),
    ...mapGetters("templatesData", [
      "getTemplatesData",
      "getTemplatesPrimaryData",
    ]),

    ...mapGetters("entityRelationships", [
      "getEntityRelationships",
      "getEntityNestedRelationships",
      "getCurrentEntityRelationships",
    ]),
    ...mapGetters("filters", ["getSingleFilterData", "getAllEntityFilters"]),
    getIndexFromStep() {
      return this.step - 1;
    },
    getStepDetails() {
      if (
        this.getEntityDataById &&
        this.getEntityDataById.templates &&
        this.getEntityDataById.templates.length
      ) {
        return this.getEntityDataById.templates[this.getIndexFromStep];
      }
      return {};
    },
    getAllSteps() {
      if (this.getEntityDataById && this.getEntityDataById.templates) {
        if (
          this.$route.query?.filter &&
          this.getSingleFilterData?.permissions?.templates &&
          this.getEntityDataById._id == this.getSingleFilterData.entity_id
        ) {
          return this.getEntityDataById.templates.filter(
            (e) =>
              this.getSingleFilterData?.permissions?.templates.indexOf(
                e.template_id
              ) != -1
          );
        }
        return this.getEntityDataById.templates;
      }
      return [];
    },
    getPrimaryFields() {
      if (this.getEntityDataById && this.getEntityDataById.primaryFields) {
        return this.getEntityDataById.primaryFields;
      }
      return [];
    },
    getStepUrl() {
      return (step, status, key) => {
        let query = this.getNavigationQuery(this.$route.query);
        let additionalQuery = "";
        if (query.group) {
          let string = {
            group: query.group,
            routeType: query.routeType,
            key: query.key,
          };
          additionalQuery = "&" + new URLSearchParams(string).toString();
        }
        if (status) {
          let url =
            `/entity_data_view/${this.$route.params.entity_id}/${
              step.templateInfo.slug
            }?dataId=${this.entityDataId || ""}` + additionalQuery;
          if (this.$route.query.filter) {
            url = url + "&filter=" + this.$route.query.filter;
          }
          if (this.$route.query.fromCustom) {
            url = url + "&fromCustom=" + this.$route.query.fromCustom;
          }
          return url;
        } else {
          let url =
            `/entity_data_view/${this.$route.params.entity_id}/${key}?dataId=${
              this.entityDataId || ""
            }&templateKey=${key}` + additionalQuery;
          if (this.$route.query.filter) {
            url = url + "&filter=" + this.$route.query.filter;
          }
          if (this.$route.query.fromCustom) {
            url = url + "&fromCustom=" + this.$route.query.fromCustom;
          }
          return url;
        }
      };
    },

    getStepEntityRelationshipUrl() {
      return (reationship) => {
        let slug = reationship.relationship_slug;
        let id = reationship.relationship_id;
        let url = `/entity_data_view/${
          this.$route.params.entity_id
        }/${slug}?dataId=${this.entityDataId || ""}&relationship_id=${id}`;
        if (this.$route.query.filter) {
          url = url + `&filter=${this.$route.query.filter}`;
        }
        if (this.$route.query.fromCustom) {
          url = url + "&fromCustom=" + this.$route.query.fromCustom;
        }
        return url;
      };
    },
    getClass() {
      return (step) => {
        if (
          this.$route.params.template_code == step.relationship_slug ||
          (step.templateInfo &&
            this.$route.params.template_code == step.templateInfo.slug)
        ) {
          return "list-group-item list-group-item-action active";
        } else {
          return "list-group-item list-group-item-action";
        }
      };
    },
    checkIsFilteredData() {
      if (
        this.$route.query?.filter &&
        this.getSingleFilterData &&
        this.getEntityDataById &&
        this.getEntityDataById._id == this.getSingleFilterData.entity_id
      ) {
        return false;
      }
      return true;
    },
  },
  methods: {
    checkPerimission(access) {
      if (
        this.$route.query?.filter &&
        this.getEntityDataById &&
        this.getSingleFilterData &&
        this.getEntityDataById._id == this.getSingleFilterData.entity_id &&
        this.getSingleFilterData?.permissions?.access_set
      ) {
        if (!this.getSingleFilterData.permissions.access_set.length) {
          return true;
        }
        return (
          this.getSingleFilterData.permissions.access_set.indexOf(access) === -1
        );
      }
      return false;
    },
    ...mapActions({
      fetchEntityDocumets: "entities/fetchEntitiesDocuments",
    }),
    displayToggle() {
      this.toggleMenu = !this.toggleMenu;
    },
    getNumber(number) {
      this.number = number;
    },
    async handleStats(val) {
      let query = this.getNavigationQuery(this.$route.query);
      this.$router.push({
        path: `/entity_data_view/${this.$route.params.entity_id}/${this.$route.params.template_code}`,
        query: {
          ...query,
          dataId: this.entityDataId || "",
          activeStep: val ? val : 1,
        },
      });
      let configId = " ";
      if (
        this.getEntityDocumentsData &&
        this.getEntityDocumentsData[0] &&
        this.getEntityDocumentsData[0].configurable_document_id &&
        this.$route.query &&
        this.$route.query.activeStep != 1 &&
        this.$route.query.activeStep != 4
      ) {
        configId = this.getEntityDocumentsData[0].configurable_document_id;

        let query = this.getNavigationQuery(this.$route.query);
        this.$router.push({
          path: `/entity_data_view/${this.$route.params.entity_id}/${this.$route.params.template_code}`,
          query: {
            ...query,
            dataId: this.entityDataId || "",
            activeStep: val ? val : 1,
            documentTemplateId: configId,
          },
        });
      }
    },
    async fetchEntityDocumets() {
      try {
        this.configureLoading = true;
        this.LoadingText = "Fetching Entity Document Templates...";
        this.templates = [];
        let params = {
          entity_id: this.entityId,
          entity_data_id: this.entityDataId,
        };
        await this.$store.dispatch("entities/fetchEntitiesDocuments", params);
        setTimeout(() => {
          this.configureLoading = false;
        }, 600);
        if (this.getEntityDocumentsDataStatus) {
          this.entityDocumentTemplates = this.getEntityDocumentsData;
          let data = this.entityDocumentTemplates.map(
            (o) => o.configurable_document_id
          );
          this.documentTemplates = this.entityDocumentTemplates.filter(
            ({ configurable_document_id }, index) =>
              !data.includes(configurable_document_id, index + 1)
          );
          this.loading = false;
          this.documentTemplates.filter((el) => {
            if (
              el &&
              el.configurable_document_data &&
              el.configurable_document_data.is_template
            ) {
              return this.templates.push(el);
            } else {
              return this.array.push(el);
            }
          });
          this.templatesCount = this.templates.length;
        } else {
          this.$notify.error({
            title: "Error",
            message: "Error in fetching entity documents",
          });
        }
      } catch (err) {
        console.log("fetchEntityDocumets",err);
      }
    },
    getEmploeeNameUsedInEntity() {
      let name =
        this.getTemplatesData &&
        this.getTemplatesData.length &&
        this.getTemplatesData.find((o) => o.template_data);
      this.employeeName = name?.template_data?.name;
      //name?.template_data?.name
      return this.employeeName;
    },
    async getNecessaryInfo() {
      this.loading = true;
      this.LoadingText = "Fetching Entities Data...";
      this.entityDataId = this.$route.query.dataId;
      if (this.entityDataId) {
        this.fetchEntityData(this.entityDataId);
      }
      this.step = this.$route.query.step || 1;
      this.activeStep = parseInt(this.$route.query.activeStep)
        ? parseInt(this.$route.query.activeStep)
        : 1;
      setTimeout(() => {
        this.loading = false;
      }, 800);
      this.entityId = this.$route.params.entity_id;
      let param = {
        entity_id: this.entityId,
        filter: this.$route.query?.filter ? this.$route.query.filter : "",
      };
      if (this.$route.query?.filter) {
        await this.$store.dispatch(
          "filters/getFilterById",
          this.$route.query?.filter
        );
      }
      await this.$store.dispatch("entities/fetchEntityById", param);
      if (this.getAllSteps && this.getAllSteps.length) {
        if (!this.$route.params.template_code) {
          this.gotoStep(this.getAllSteps[0]);
        }
      }
      if (
        this.getPrimaryFields &&
        this.getPrimaryFields.length &&
        this.entityDataId
      ) {
        await this.$store.dispatch(
          "templatesData/fetchUserTemplatesPrimaryData",
          {
            entity_data_id: this.entityDataId,
            template_id: this.getPrimaryFields[0].template_id,
          }
        );
      }
      await this.fetchEntityDocumets();
      await this.fetchEntityRelationships();
      await this.fetchEntityNestedRelationships();
    },

    async fetchEntityRelationships() {
      this.loading = true;
      this.loadingText = "Fetching Entity relationships..";
      // await this.$store.dispatch(
      //   "entityRelationships/fetchEntityRelationships",
      //   { entity_id: this.entityId }
      // );
       this.fetchedRelationships = await fetchEntityRelationships({
        entity_id: this.entityId,
      });
      this.relationshipsData = [...(this.fetchedRelationships || [])];
      // we have to add type owner of relationship
      this.relationshipsData = this.relationshipsData.map((e) => {
        if (e.parent_entity_id == this.$route.params.entity_id) {
          e.owner_type = "PARENT";
          e.relationship_title = e.child_relation_title;
          e.relationship_slug = makeSlug(e.relationship_title);
          e.relationship_id = e.child_entity_id;
        } else {
          e.owner_type = "CHILD";
          e.relationship_title = e.parent_relation_title;
          e.relationship_slug = makeSlug(e.relationship_title);
          e.relationship_id = e.parent_entity_id;
        }
        e.parent_entity_data_id = this.entityDataId;
        return e;
      });

      this.relationshipsData = this.relationshipsData.filter((e) => {
        if (e.owner_type == "CHILD" && !e.is_bidirectional) {
          return false;
        } else {
          return true;
        }
      });
      this.$store.dispatch(
        "entityRelationships/updateCurrentEntityRelationships",
        this.relationshipsData
      );

      this.relationshipsData = this.relationshipsData.filter(
        (e) => e.owner_type == "CHILD" || e.representation == "TAB"
      );

      this.loading = false;
    },

    async fetchEntityNestedRelationships() {
      this.loading = true;
      this.loadingText = "Fetching  Nested Entity relationships...";
      await this.$store.dispatch(
        "entityRelationships/fetchEntityNestedRelationships",
        { entity_id: this.entityId }
      );

      this.nestedRelationshipsData = [
        ...(this.getEntityNestedRelationships || []),
      ];
      // we have to add type owner of relationship
      this.nestedRelationshipsData = this.nestedRelationshipsData.map((e) => {
        e.owner_type = "PARENT";
        e.relationship_title =
          e.parent_relationship_details.relationship_title +
          "/" +
          e.child_relation_title;
        e.relationship_slug =
          e.parent_relationship_details.relationship_slug +
          "-" +
          makeSlug(e.relationship_title);
        e.relationship_id = e.child_entity_id;
        e.parent_entity_data_id = this.entityDataId;
        return e;
      });

      this.$store.dispatch(
        "entityRelationships/updateCurrentEntityNestedRelationships",
        this.nestedRelationshipsData
      );

      this.loading = false;
    },

    async gotoStep(step) {
      let query = this.getNavigationQuery(this.$route.query);
      this.$router.push({
        path: this.getStepUrl(step, true, ""),
        query: query,
      });
    },
    gotoNextStep() {
      const slug = this.$route.params.slug;
      const currentStepIndex = this.getAllSteps.findIndex(
        (x) => x.slug == slug
      );
      if (
        currentStepIndex > -1 &&
        currentStepIndex < this.getAllSteps.length - 1
      ) {
        this.gotoStep(this.getAllSteps[currentStepIndex + 1]);
      }
    },

    async backToEntityDataList() {
      if (this.$route.query?.fromCustom) {
        this.$router.go(-2);
      } else {
        let query = this.getNavigationQuery(this.$route.query);
        this.$router.push({
          path: "/entity/" + this.entityId,
          query: query,
        });
      }
    },
  },
  watch: {
    "$route.query.step"(step) {
      this.step = step;
    },
    "$route.params.slug"(slug) {
      this.slug = slug;
    },
    "$route.query.activeStep"(step) {
      this.activeStep = parseInt(step) ? parseInt(step) : 1;
    },
    "$route.query.documentTemplateId"(step) {
      this.documentTemplateId = parseInt(step) ? parseInt(step) : 1;
      // this.resetData();
    },
  },
};
</script>

<style lang="scss" scoped>
.entity-data-view-section {
  .list-group1 {
    margin-top: 35px;
    width: 255px;
    border-bottom: 1px ridge;
    .list-group-item {
      margin-right: -20px;
      margin-left: -20px;
      padding: 0em;
      border: none;
      color: #9299b8;
      &.active {
        color: #f754a2;
        border-left: 3px solid #f754a2;
      }
    }
  }
  .list-group-res {
    margin-top: 35px;
    border-bottom: 1px ridge;
    .list-group-item {
      margin-right: -20px;
      margin-left: -20px;
      padding: 0em;
      border: none;
      color: #fdfdfd;
      &.active {
        color: #f754a2;
        border-left: 3px solid #f754a2;
      }
    }
  }
  .list-group-menu {
    // margin-top: 35px;
    // border-bottom: 1px ridge;
    position: absolute;
    margin-top: 50px;
    z-index: 99;
    width: 150px;
    .list-group-item {
      margin-right: -20px;
      margin-left: -20px;
      padding: 0em;
      border: none;
      color: #fdfdfd;
      &.active {
        color: #f754a2;
        border-left: 3px solid #f754a2;
      }
    }
  }
  .list-group {
    margin-top: 35px;
    width: 255px;
    border-bottom: 1px ridge;
    .list-group-item {
      margin-right: -20px;
      margin-left: -20px;
      padding: 0em;
      border: none;
      color: #9299b8;
      &.active {
        color: #f754a2;
        border-left: 3px solid #f754a2;
      }
    }
  }
  .iconStyle {
    width: 27px;
    margin-left: 100px;
    margin-top: -15px;
    margin-bottom: 10px;
  }
  .iconResStyle {
    width: 20px;
    margin-left: 50px;
    margin-top: -10px;
    margin-bottom: 10px;
  }
  .activeStyle {
    width: 35px;
    margin-left: 110px;
    margin-top: -9px;
    margin-bottom: 10px;
  }
  .textstatstyle {
    cursor: pointer;
    margin-top: 8px;
    margin-left: 10px;
    font-size: 12px;
    font-weight: 400;
    color: #525f67;
  }
  .textActiveStyle {
    cursor: pointer;
    margin-top: 8px;
    margin-left: 10px;
    font-size: 12px;
    font-weight: 600;
    color: #ffffff;
  }
  .number_sty {
    color: black;
    margin-top: -43px;
    margin-left: 12px;
  }
  .number_sty_active {
    color: #ffffff;
    margin-top: -43px;
    margin-left: 12px;
  }
  .statstyle {
    margin-top: 1px;
    margin-left: 20px;
    border-radius: 10px;
    width: 150px;
    height: 65px;
  }
  .resStyle {
    margin-top: 19px;
    margin-left: 20px;
    border-radius: 10px;
    width: 90px;
    height: 55px;
  }
  .header-action-block {
    padding: 0px 0px;
    display: flex;
    button {
      margin-right: 10px;
    }
    // h5 {
    //   margin-top: 10px;
    // }
  }
  .preview-template-view {
    .footer-action-block {
      .edit-btn {
        margin-top: 5px;
        margin-right: 5px;
      }
    }
    .form-builder {
      .form-fields-holder {
        @media (max-width: 991.98px) {
          & > div {
            position: relative !important;
            left: 10px !important;
            top: 10px !important;
            margin-top: 15px;
            width: calc(100% - 20px) !important;
            input {
              width: 100%;
            }
            .heading {
              margin-top: 40px !important;
            }
          }
        }
      }
    }
  }
}
.sidemenu-one {
  height: 450px;
  overflow: scroll;
  border-top: 1px ridge;
  border-left: 1px ridge;
  border-bottom: 1px ridge;
}
.sidemenu {
  height: 450px;
  overflow-y: scroll;
  overflow-x: scroll;
  border-top: 1px ridge;
  border-left: 1px ridge;
  border-bottom: 1px ridge;
}
.sidemenu::-webkit-scrollbar {
  width: 0.6em;
}
.sidemenu::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  background: #ffffff;
  -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9);
}
.sidemenu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #f5f5f5;
  background-color: #f5f5f5;
}
.secondcol {
  margin-top: 50px;
  width: 75%;
  @media (max-width: 766.98px) {
    width: 70%;
  }
}
.secondcol-res {
  // margin-top: 50px;
  // width: 75%;
}
.imgsty {
  width: 40px;
  height: 40px;
}
.namesty {
  margin-top: -35px;
  margin-left: 50px;
}
.entity-data-view-section {
  .all-documents-view {
    @media (max-width: 766.98px) {
      margin-top: 30px;
    }
    .doc-modal {
      .el-dialog {
        width: 800px !important;
        padding-bottom: 15px;
        @media (max-width: 800px) {
          width: 700px !important;
          margin-top: 40px !important;
        }
        @media (max-width: 700px) {
          width: 600px !important;
          margin-top: 40px !important;
        }
        @media (max-width: 600px) {
          width: 500px !important;
          margin-top: 40px !important;
        }
        @media (max-width: 500px) {
          width: 400px !important;
          margin-top: 40px !important;
        }
        @media (max-width: 400px) {
          width: 300px !important;
          margin-top: 40px !important;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.el-message-box__wrapper {
  .el-message-box {
    @media (max-width: 450px) {
      width: 315px !important;
      margin-top: 100px;
      vertical-align: top;
    }
  }
}
.entity-form-execute {
  .template-body-section {
    .form-builder {
      .form-fields-holder {
        @media (max-width: 991.98px) {
          & > div {
            position: relative !important;
            left: 10px !important;
            top: 0px !important;
            margin-top: 30px;
            width: calc(100% - 20px) !important;
            input {
              width: 100%;
            }
            .heading {
              margin-top: 40px !important;
            }
            p {
              width: 100% !important;
            }
          }
        }
      }
    }
  }
}
</style>
